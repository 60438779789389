import { useEffect, useState } from "react";
import { DepartmentModel } from "../Api/Model/DepartmentModel";
import { useAxios } from "./useAxios";
import { emptyGUID } from "../constants/guid.contast";
import { PaginationResult } from "../Api/Model/Shared/PaginationResult";
import { AdvancedFilter } from "../Api/Model/AdvancedFilterModel";
import OfficeProviderModel from "../Api/Model/DepartmentStructure/OfficeProviderModel";
import PatientsMessagesCount from "../Api/Model/Patient/PatientMessages/PatientsMessagesCount.model";
import { CatalogModel } from "../Api/Model/GenericCatalog/CatalogModelAxios";
import ConfigurationSettingModel from "../Api/Model/ConfigurationSetting/ConfigurationSettingModel";

export const usePatientList = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [patients, setPatients] = useState<any>([]);
  const [organizations, setOrganizations] = useState<DepartmentModel[]>([]);
  const [officeProviders, setOfficeProviders] = useState<OfficeProviderModel[]>([]);
  const [patientTypes, setPatientTypes] = useState<CatalogModel[]>([])
  const [ccmColors, setCcmColors] = useState<ConfigurationSettingModel[]>([])
  const [numberOfAlerts, setNumberOfAlerts] = useState(0);
  const { getAsync, axiosError } = useAxios();
  const [pagination, setPagination] = useState<PaginationResult>({ TotalRecords: 1, PageSize: 10 });
  const { postAsync: getPatientsAsync, axiosPagination, axiosSuccess } = useAxios();
  const { getAsync: getPatientMessagesCountAsync, axiosSuccess:getPatientMessagesSuccess } = useAxios();
  const [rowCountState, setRowCountState] = useState(1);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [userFilter, setUserFilter] = useState<AdvancedFilter>({
    DepartmentId: "",
    ProviderId: "",
    Sex: '',
    CareTeam: '',
    Status: '',
    PatientTypeId: '',
  });
  const [shouldUpdatePatients, setShouldUpdatePatients] = useState<boolean>(false);

  const { manageErrorAlert } = props;

  async function setPatientTypesFromDB() {
    const axiosGet = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Patient Type"}`);
    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0])
      return;
    }
    
    setPatientTypes(axiosGet);
  }

  async function setColorsFromDB() {

    let ccmColorsList: ConfigurationSettingModel[] = []
    const axiosGetEven = await getAsync<ConfigurationSettingModel>(`ConfigurationSettings/GetConfigurationSettingByName?name=CcmEvenColor`);
    if (!axiosGetEven) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    ccmColorsList.push(axiosGetEven);

    const axiosGetOdd = await getAsync<ConfigurationSettingModel>(`ConfigurationSettings/GetConfigurationSettingByName?name=CcmOddColor`);
    if (!axiosGetOdd) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    ccmColorsList.push(axiosGetOdd);
    setCcmColors(ccmColorsList);
  }

  async function setOfficeProvidersFromBD() {
    const axiosGet = await getAsync<OfficeProviderModel[]>(`Provider/GetProvidersAndOfficesByDepartmentStructure?DepartmentId=${userFilter.DepartmentId || emptyGUID}`, {
      PageNumber: 0,
      PageSize: 0
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setOfficeProviders(axiosGet);
  }

  async function setDepartmentsFromBD() {
    const axiosGet = await getAsync<DepartmentModel[]>("Department/GetDepartments", {
      PageNumber: 0,
      PageSize: 0
    });

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    setOrganizations(axiosGet);
  }

  useEffect(() => {
    setRowCountState((prevState) =>
      axiosPagination?.TotalRecords !== undefined ? axiosPagination?.TotalRecords : prevState
    );
  }, [axiosPagination?.TotalRecords]);

  useEffect(() => {
    setPagination({ ...pagination, PageNumber: page + 1, PageSize: pageSize });
  }, [page, pageSize]);

  useEffect(() => {
    setShouldUpdatePatients(true);
  }, []);

  useEffect(() => {
    if (shouldUpdatePatients) {
      setPatientsFromBD();
      setColorsFromDB();
    }
  }, [shouldUpdatePatients]);

  async function setPatientsFromBD() {
    setIsLoading(true);
    
    let patients = await getPatientsAsync<any>(`Patients/GetPatientDashBoard`, 
      userFilter || {} as AdvancedFilter);

    if (!patients && !axiosSuccess) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }

    if(!patients) {
      patients = [];
    }

    let numberOfAlertsT = await getAsync<number>(`Patients/GetTotalPatientAlerts`);
    setNumberOfAlerts(numberOfAlertsT || 0);
    setIsLoading(false);
    setShouldUpdatePatients(false);
    setPatients(patients);
  }

  const getPatientMessagesCount = async () => {
    let patientsWithMessagesResult: Array<PatientsMessagesCount> = [];
    const patientsWithMessagesRequest = await getPatientMessagesCountAsync<Array<PatientsMessagesCount>>("PatientMessages/UnReadMessageCounter");
    if(patientsWithMessagesRequest && getPatientMessagesSuccess){
      patientsWithMessagesResult = patientsWithMessagesRequest;
    }
    return patientsWithMessagesResult;
  }

  useEffect(() => {
    if (organizations.length === 0) {
      setDepartmentsFromBD();
    }

    setPatientTypesFromDB();
    setOfficeProvidersFromBD();
  }, [userFilter.DepartmentId]);

  return {
    numberOfAlerts,
    setNumberOfAlerts,
    officeProviders,
    setOfficeProviders,
    organizations,
    setOrganizations,
    patientTypes,
    setPatientTypes,
    ccmColors,
    patients,
    setPatients,
    isLoading,
    setIsLoading,
    patientRowCountState: rowCountState,
    patientPagination: axiosPagination,
    setPatientPagination: setPagination,
    patientPage: page,
    setPatientPage: setPage,
    setPatientPageSize: setPageSize,
    setPatientFilter: setUserFilter,
    patientFilter: userFilter,
    updatePatients: setShouldUpdatePatients,
    getPatientMessagesCount
  };
};
