import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { PatientAdress } from "../Api/Model/AddressModel";
import { PatientModel } from "../Api/Model/PatientModel";
import { UserModel } from "../Api/Model/user/userModel";
import { patienRolName } from "../constants/global.constants";
import { emptyGUID } from "../constants/guid.contast";
import { useUser } from "./useUserInfo";
import { useAxios } from "./useAxios";
import { PatientInsuranceModel } from "../Api/Model/Patient/PatientInsurance";
import DepartmentStructureModel from "../Api/Model/DepartmentStructure/DepartmentStructureModel";
import { sortStringsWithNumbers } from "../Shared/Sorting.provider";
import { CatalogModel } from "../Api/Model/GenericCatalog/CatalogModelAxios";
import { PatientPhoneNumber } from "../Api/Model/PatientPhoneNumberModel";

export const usePatient = (props: any, manageErrorAlert: Function, showAlertSnack: Function) => {
  const [sexs, setSexs] = useState<CatalogModel[]>([]);
  const [communicationPreferences, setCommunicationPreferences] = useState<CatalogModel[]>([]);
  const [patientTypes, setPatientTypes] = useState<CatalogModel[]>([]);
  const [contactMethods, setContactMethods] = useState<CatalogModel[]>([]);
  const [patientStatus, setPatientStatus] = useState<CatalogModel[]>([]);
  const [existentPatient, setExistentPatient] = useState(false);
  const { addUserToPatientFromBD } = useUser(props, undefined, manageErrorAlert, showAlertSnack);
  const { deleteAsync, updateAsync, postAsync, getAsync, axiosError, axiosSuccess } = useAxios();
  const { getAsync: getPatientExists, axiosLoading } = useAxios();

  const getCatalogsInfo = async () => {
    const axiosGetGender = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Gender"}`);
    if (!!axiosGetGender) {
      setSexs(axiosGetGender);
    }
    const axiosGetPatientTypes = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Patient Type"}`);
    if (!!axiosGetPatientTypes) {
      setPatientTypes(axiosGetPatientTypes);
    }
    const axiosGetCommunication = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Communication Type"}`);
    if (!!axiosGetCommunication) {
      let sortedResponse = axiosGetCommunication.sort((providerA: CatalogModel, providerB: CatalogModel) =>
        sortStringsWithNumbers(providerA.catalogName, providerB.catalogName)
      );
      setCommunicationPreferences(sortedResponse);
    }
    const axiosGetStatus = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Patient Status"}`);
    if (!!axiosGetStatus) {
      setPatientStatus(axiosGetStatus);
    }
    const axiosGetContactMethods = await getAsync<CatalogModel[]>(`Catalogs/GetSubCatalogsByCatalogName/${"Contact Method"}`);
    if (!!axiosGetContactMethods) {
      setContactMethods(axiosGetContactMethods);
    }
  }

  useEffect(() => {
    getCatalogsInfo();
  }, []);

  function anyModelToPatient(patientToAdd: PatientModel, patientUserId: string, patientId: string, structure?: DepartmentStructureModel[]) {
    let dateToday = dayjs();
    let patientAdressFoundId = patientToAdd.patientAddressId || null;
    let selectedContactMethod = null;
    let selectedCommunicationPreference = null;
    let ageCalc = dateToday.diff(patientToAdd.birthDateTime, "year");
    let selectedSex = patientToAdd.gender !== undefined && patientToAdd.gender !== "" ? sexs.filter(
      (sex) => sex.catalogName === patientToAdd.gender
    )[0].catalogId : null;
    let selectedPatientType = patientToAdd.patientType !== undefined && patientToAdd.patientType !== "" ? patientTypes.filter(
      (type) => type.catalogName === patientToAdd.patientType
    )[0].catalogId : null;
    if (patientToAdd.contactMethod) {
      selectedContactMethod = contactMethods.filter(
        (cm) => patientToAdd.contactMethod === cm.catalogName
      )[0].catalogId;
    }
    if (patientToAdd.communicationType) {
      selectedCommunicationPreference = communicationPreferences.filter(
        (cp) => patientToAdd.communicationType === cp.catalogName
      )[0].catalogId;
    }
    let PatientAdressNew: PatientAdress = {};
    PatientAdressNew = { addressId: patientAdressFoundId || undefined, streetLine1: patientToAdd.patientAddress?.streetLine1 ? patientToAdd.patientAddress?.streetLine1.trim() : '', streetLine2: patientToAdd.patientAddress?.streetLine2 ? patientToAdd.patientAddress?.streetLine2.trim() : '', city: patientToAdd.patientAddress?.city ? patientToAdd.patientAddress?.city?.trim() : '', state: patientToAdd.patientAddress?.state ? patientToAdd.patientAddress?.state?.trim() : '', zipCode: patientToAdd.patientAddress?.zipCode ? patientToAdd.patientAddress?.zipCode?.trim() : '' };

    if (patientToAdd.user) {
      patientToAdd.user.phoneNumber = patientToAdd.phoneNumber?.trim();
    }

    let newUserModel: PatientModel = {
      userId: patientUserId,
      genderId: selectedSex || undefined,
      patientTypeId: selectedPatientType || undefined,
      patientAddressId: patientAdressFoundId,
      contactMethodId: selectedContactMethod,
      communicationTypeId: selectedCommunicationPreference,
      firstName: patientToAdd.firstName?.trim(),
      lastName: patientToAdd.lastName?.trim(),
      age: ageCalc,
      birthDateTime: patientToAdd.birthDateTime,
      provider: patientToAdd.provider,
      officePhoneNumber: patientToAdd.officePhoneNumber,
      nurseWeeklyCall: patientToAdd.nurseWeeklyCall,
      nurseMonthlyCall: patientToAdd.nurseMonthlyCall,
      nickname: patientToAdd.nickname,
      noComplianceMessaging: patientToAdd.noComplianceMessaging,
      patientAddress: PatientAdressNew,
      isActive: true,
      patientStatusName: patientToAdd.patientStatusName,
      patientStatusId: patientToAdd.patientStatusId,
      patientId: patientId === '' ? undefined : patientId,
      patientInsurancesList: patientToAdd.patientInsurancesList,
      patientStructure: structure,
      user: patientToAdd.user
    };
    return newUserModel;
  }
  async function addNewPatient(patientToAdd: any, patientInsurances: PatientInsuranceModel[], structure?: DepartmentStructureModel[]) {
    let patientUserId = emptyGUID;
    patientToAdd.patientInsurancesList = patientInsurances;
    patientUserId = await addUserFromPatient(patientToAdd);
    let newUserModel = anyModelToPatient(patientToAdd, patientUserId, '', structure);
    if (emptyGUID !== patientUserId) {
      const newPatientId = await postAsync('Patients/AddPatient', newUserModel);
      if (axiosSuccess) {
        showAlertSnack("Patient created successfully.", "success");
        return newPatientId;
      }
      else {
        manageErrorAlert(axiosError?.Messages[0]);
      }
    }
  }

  async function checkUserExist(username: string) {
    const axiosGet = await getAsync<boolean>(`User/ValidateUserNameIsInUse?UserName=${username}`);
    setExistentPatient(axiosGet || false);
  }

  const checkPatientExist = async (patientId: string) => {
    const patientExists = await getPatientExists<boolean>(`Patients/PatientExists?UuId=${patientId}`);
    return patientExists || false;
  }

  async function addUserFromPatient(patientToAdd: PatientModel) {
    let newUser: UserModel = { userName: patientToAdd.user?.userName?.trim(), roleName: patienRolName, password: patientToAdd.user?.password?.trim(), contact: "", firstName: patientToAdd.firstName?.trim(), lastName: patientToAdd.lastName?.trim(), email: patientToAdd.user?.email?.trim(), phoneNumber: patientToAdd.phoneNumber?.trim(), internalCode: "" };
    let userCreated: any = await addUserToPatientFromBD(newUser);
    return userCreated.id;
  }

  async function updatePatient(patientId: string, patientToUpdate: any, patientInsurances: PatientInsuranceModel[]) {
    patientToUpdate.patientInsurancesList = patientInsurances;
    let newUserModel: PatientModel = anyModelToPatient(patientToUpdate, patientToUpdate.userId, patientId);
    await updateAsync<PatientModel>('Patients/UpdatePatients', patientId || '', newUserModel);
    if (axiosSuccess) {
      showAlertSnack("The patient was successfully updated.", "success");
    }
    else {
      manageErrorAlert(axiosError?.Messages[0]);
    }
  }


  async function deletePatient(patientId: string) {
    await deleteAsync('Patients/DeletePatient', patientId || '');

    if (axiosSuccess) {
      showAlertSnack("The patient was successfully removed.", "success");
    }
  }

  function generateRandomPwd() {
    return Math.random().toString(36).slice(-8);
  }
  async function getPatientById(patientId: string) {
    const axiosGet = await getAsync<PatientModel>(`Patients/GetPatientById?UuId=${patientId}`);

    if (!axiosGet) {
      manageErrorAlert(axiosError?.Messages[0]);
      return;
    }
    return axiosGet;
  }

  const retrivePatientId = async (patientId: string) => {
    const axiosGet = await getAsync<string>(`Patients/GetPatientInternalCodeById?uuId=${patientId}`);
    return axiosGet || '';
  }

  const getPatientPhoneNumbers = async (patientId: string) => {
    const axiosGet = await getAsync<PatientPhoneNumber[]>(`PatientInfo/GetPhoneNumbersByPatientId/${patientId}`);
    return axiosGet || [];
  }

  const updatePatientPhoneNumbers = async (phoneNumbers: PatientPhoneNumber[], patientId: string) => {
    await postAsync<PatientPhoneNumber[]>(`PatientInfo/UpdatePhoneNumbersByPatientId/${patientId}`, phoneNumbers);
  }

  return {
    sexs,
    patientTypes,
    communicationPreferences,
    contactMethods,
    patientStatus,
    checkUserExist,
    addNewPatient,
    generateRandomPwd,
    checkPatientExist,
    retrivePatientId,
    getPatientPhoneNumbers,
    updatePatientPhoneNumbers,
    getPatientById,
    existentPatient,
    updatePatient,
    deletePatient,
    axiosError,
    axiosLoading
  };
};
